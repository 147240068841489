import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  IntroWrapper,
  IntroTitle,
  Desciption,
  IntroContentWrapper,
} from "./style"

type IntroProps = {}

const Intro: React.FunctionComponent<IntroProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  const { about } = Data.site.siteMetadata

  return (
    <IntroWrapper>
      <IntroContentWrapper>
        <IntroTitle>The Best Car Deals, Personalized for YOU!</IntroTitle>
        <Desciption>{about}</Desciption>
      </IntroContentWrapper>
    </IntroWrapper>
  )
}

export default Intro
